*{
    letter-spacing: .0125em;
}

a {
    color: darkgrey;
}

.container {
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
}

.box {
    /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
    max-width: 492px;
    width: 95%;
    /* background-image: linear-gradient(140.14deg,rgba(0,182,191,.15),rgba(27,22,89,.1) 86.61%),linear-gradient(321.82deg,#18134d,#1b1659)!important; */
    border-radius: 8px;
    background: hsla(0,0%,100%,.05);
    height: fit-content;
    backdrop-filter: blur(12px);
}

.box-header {
    width: 100%;
}

.box-header > span {
    display: flex;
    justify-content: space-between;
}

.box-header > span > span {
    font-size: 13px;
}

.banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 240px;
    /* background-color: rgb(26, 26, 28); */
    border-radius: 8px;
    height: 140px;
}

.card-title {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.25rem;
    letter-spacing: .0125em;
    line-height: 2rem;
    word-break: break-all;
}

.select-dropdown {
    display: flex;
    align-items: center;
}

.select-dropdown > img{
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin-right: 10px;
}

.box-content {
    padding: 16px;
}

.rate-limit-text {
    color: #b5b4b4;
    font-size: 13px;
    font-weight: 300;
    word-break: break-word;
    letter-spacing: 1px;
    line-height: 20px;
}

.rate-limit-text > span {
    margin-left: 2px;
    font-weight: 500;
}

.address-input {
    display: flex;
    align-items: center;
    color: #333;
    height: 45px;
    background-color:#28272bf0;
    margin-bottom: 8px;
    border-radius: 5px;
}

.address-input > input {
    background-color: transparent;
    border-style: none;
    color: white;
    outline: none;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0 12px;
}

.connect-metamask {
    cursor: pointer;
    color: rgb(180, 180, 183);
    margin-right: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 10px
}

.connect-metamask > img {
    width: 25px;
    height: 25px;
}

.connect-metamask:hover {
    color: white;
}

.beta-alert {
    color: #ccc;
    font-size: 15px;
    /* border: thin #ff5252 solid; */
    text-align: center;
    border-radius: 4px;
    margin-top: 20px;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.send-button {
    color:white;
    margin-top: 20px;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    border: 0px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    text-transform: uppercase;
}

.send-button:hover {
    background-color: #fff;
    color: #14103f;
}

.send-button-disabled {
    color:#000;
    /* margin-top: 20px; */
    height: 36px;
    width: 100%;
    padding: 0 16px;
    border: 0px;
    border-radius: 4px;
    background:linear-gradient(45deg,#8fd41f,#ffe569 40%);
    cursor: not-allowed;
    text-transform: uppercase;
}

.bold-text {
    font-size: small;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.536);
}

.back-button {
    color:white;
    margin-top: 10px;
    height: 36px;
    width: 100px;
    padding: 0 16px;
    border: 0px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    text-transform: uppercase;
}

.back-button:hover {
    background-color: #333;
}