body::-webkit-scrollbar {
    width: 2px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0, 0, 0);
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(113, 113, 113);
}

.app {
    font-family: Avenir,Helvetica,Arial,sans-serif;
    padding-top: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: url(https://wyzth.org/images/grid.svg);
    background-color: #090a0c;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;

}

@media screen and (max-width: 1010px) {
    .app {
        padding-top: 10%;
        padding-bottom: 10%;
    }
}
.css-pyd4mq-control {
    align-items: center;
    /* background-color: hsl(0, 0%, 100%); */
    border-color: #28272bf0 !important;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    background: #28272bf0 !important;
}
.css-11gcyr5-control{
    background: #1b1659 !important;   
}